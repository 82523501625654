import { PortableTextTypeComponentProps } from "@portabletext/react"

import Video from "Components/Video"

interface IVideoEmbedProps {
    id: string
    service: string
    caption: string
}

export default function VideoEmbed(
    props: PortableTextTypeComponentProps<IVideoEmbedProps>
) {
    const { id, service, caption } = props.value

    return (
        <Video
            id={id}
            service={service}
            caption={caption}
        />
    )
}
